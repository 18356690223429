<template>
  <div class="container">
    <div @click="goback" style="font-size:14px;margin-bottom:10px;cursor: pointer;width:50px">
      <i class="el-icon-back"></i>
      返回
    </div>
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item prop="client_app_code" label="app编号" label-width="150px">
        <el-select v-model="form.client_app_code" @change="appSelect">
          <el-option
            v-for="item in applist"
            :key="item.code"
            :label="item.name"
            :value="item.code">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="package_name" label="APP应用包名" label-width="150px">
        <el-select v-model="form.package_name">
          <el-option
            v-for="item in packageList"
            :key="item.value"
            :label="item.name"
            :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="new_server_key" label="新服务密钥" label-width="150px">
        <el-input v-model="form.new_server_key" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="server_key" label="服务密钥" label-width="150px">
        <el-input v-model="form.server_key" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="cert" label="推送证书地址" label-width="150px">
        <el-input v-model="form.cert" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="title" label="推送消息title" label-width="150px">
        <el-input v-model="form.title" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item prop="msg" label="Alert显示消息内容" label-width="150px">
        <el-input v-model="form.msg" type="text" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label-width="150px">
        <el-button type="primary" v-if="!edit" @click="Validate(addconfirm)">新增</el-button>
        <el-button type="primary" v-if="edit" @click="Validate(editconfirm)">修改</el-button>
      </el-form-item>
    </el-form>

  </div>
</template>

<script type="text/ecmascript-6">
export default {
  name: '',
  mixins: [],
  components: {},
  props: {},
  data () {
    return {
      form: {
        id: '',
        client_app_code: '',
        new_server_key: '',
        server_key: '',
        cert: '',
        title: '',
        msg: '',
        package_name: '',
      },
      rules: {
        client_app_code: [{ required: true, message: '请输入app编号', trigger: 'blur' }],
        new_server_key: [{ required: true, message: '请输入新服务密钥', trigger: 'blur' }],
        server_key: [{ required: true, message: '请输入服务密钥', trigger: 'blur' }],
        cert: [{ required: true, message: '请输入推送证书地址', trigger: 'blur' }],
        title: [{ required: true, message: '请输入推送消息title', trigger: 'blur' }],
        msg: [{ required: true, message: '请输入推送消息Alert显示消息内容', trigger: 'blur' }],
        package_name: [{ required: true, message: '请输入APP应用包名', trigger: 'blur' }],
      },
      edit: false,
      applist: [],
      title: '',
      packageList: []
    }
  },
  computed: {},
  watch: {},
  methods: {
    appSelect () {
      this.packageList = []
      this.applist.forEach(item => {
        if (item.code == this.form.client_app_code) {
          this.form.package_name = item.ios_package
          this.packageList.push({name: 'IOS',value:item.ios_package},{name: 'Android',value:item.android_package})
        }
      })
    },
    GetInfo () {
      this.api.FcmDetail({id:this.$route.params.id}).then(res => {
        if (res.data.code == 200) {
          this.form=res.data.data
          return
        }
        this.$message.error('获取详情失败' + res.data.msg)
      })
    },
    GetappCode() {
      this.api.ClientList({
          page: 1,
          limit: 100
      }).then(res => {
        if (res.data.code == 200) {
            this.applist = res.data.data.data
            return
        }
        this.$message.error('获取失败' + res.data.msg)
      })
    },
    Validate: function (call) {
      this.$refs['form'].validate(res => {
        if (res && call) {
          call()
        }
      })
    },
    update () {
      this.api.FcmUpdate(this.form).then(res => {
        if (res.data.code == 200) {
          this.$message.success(this.title +'成功')
          return
        }
        this.$message.error(this.title +'失败' + res.data.msg)
      })
    },
    addconfirm (){
        this.update()
    },
    editconfirm (){
        this.update()
    },
    goback() {
      this.$router.push('/iot/app/fcm')
    }
  },
  filters: {},
  mounted () {
    if (this.$route.params.id !== '0') {
      this.edit = true
      this.title = '编辑'
      this.GetInfo()
    } else {
      this.edit = false
      this.title = '新增'
    }
  },
  created () {
    this.GetappCode()
  }
}
</script>

<style scoped lang="stylus" rel="stylesheet/stylus">
.el-form {
  width: 600px;
}
</style>
